import axios from 'axios'
import HTMLReactParser, { attributesToProps, domToReact } from 'html-react-parser'
import { debounce, find, range } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useService } from '../../Container/ContainerContext'
import { settingsBaseUrl, useAppContext } from '../../Context/AppContext'
import { useCart } from '../../Context/CartContext'
import { ThemeContext } from '../../Context/ThemeContext'
import { useEvent, useEvents, useImage, usePriceCodes, useRanks } from '../../Hooks'
import { getGroupedEvents } from '../../Hooks/useGroupedEvents'
import { useLocations } from '../../Hooks/useLocations'
import { useOrder } from '../../Hooks/useOrder'
import { IPriceCode } from '../../Model'
import { ISettings } from '../../Settings/Settings'
import { settingsId } from '../../Settings/container'
import { getFloorplanImageUrl } from '../../Utils/floorplanImage'
import { priceFormatter } from '../../Utils/numbers'
import Spinner from '../Spinner'
import { AddToCartWrapper } from './Component/Overlay/styled'
import {
    AmountButton,
    Event,
    EventDate,
    EventDates,
    EventDetailDate,
    EventDuration,
    EventImage,
    EventInfo,
    EventInfoTekst,
    EventSubTitle,
    EventTitle,
    FinalizeButton,
    FinalizeInfo,
    LoadingWrapper,
    LocationName,
    Message,
    Price,
    PriceCodeCell,
    PriceCodeName,
    PriceCodeSelection,
    PriceCodeText,
    PriceCodes,
    Rank,
    RankSelection,
    RankText,
    Ranks,
    SelectedAmount,
    SvgDiv,
    TotalPrice,
    Wrapper
} from './styled'

export const EventDetail = ({ eventId }: { eventId: number }) => {
    const navigate = useNavigate()
    const appContext = useAppContext()

    const [selectedRank, setSelectedRank] = useState<null | number>(null)
    const [selectedAmounts, setSelectedAmounts] = useState<{
        [x: number]: number
    }>({})
    const [selectedLocation, setSelectedLocation] = useState<string | undefined>()
    const [hasAvailableSeats, setHasAvailableSeats] = useState<boolean>(true)
    const [maxSelectableSeats, setMaxSelectableSeats] = useState<number>(0)

    const hasSelectedAmount = () => !!find(selectedAmounts, (s) => s > 0)

    const { events } = useEvents({ filter: { type: 'id', eventId } })
    const { groupedEvents } = getGroupedEvents(events)
    const settings = useService<ISettings>(settingsId)

    const floorplanBaseUrl = appContext.appSettings?.floorplanBaseUrl || undefined

    const groupedEvent = useMemo(() => {
        return groupedEvents.find((g) => {
            return g.filter((e) => e.id === eventId).length > 0
        })
    }, [groupedEvents, eventId])

    const { status: imageStatus, image, defaultImgStyle } = useImage(eventId)
    const { status: eventStatus, event } = useEvent(eventId)
    const { status: ranksStatus, data: ranksData } = useRanks(eventId)
    const { status: priceCodeStatus, data: priceCodeData } = usePriceCodes(eventId, selectedRank)
    const { status: locationStatus, data: locationsData } = useLocations()

    const cart = useCart()

    const ranks = useMemo(() => {
        if (ranksStatus === 'success' && ranksData?.success === true) {
            return ranksData.data
        }
        return []
    }, [ranksData, ranksStatus])

    const filteredRanks = ranks.filter(
        (r) => !selectedLocation || r.locations.find((l) => l.name === selectedLocation)
    )

    const priceCodes = useMemo(() => {
        if (priceCodeStatus === 'success' && priceCodeData?.success === true) {
            return priceCodeData.data[0]
        }
        return []
    }, [priceCodeData, priceCodeStatus])

    useEffect(() => {
        if (ranks.length === 1) {
            if (ranks[0].availableSeats > 0) {
                setSelectedRank(ranks[0].id)
            } else {
                setHasAvailableSeats(false)
            }
        }
    }, [ranks])

    const selectRank = (rankId: number) => {
        setSelectedRank(rankId)
    }

    useEffect(() => {
        setSelectedAmounts({})
    }, [event, selectedRank])

    const totalPrice = useMemo(() => {
        let price = 0
        priceCodes.forEach((pc) => {
            if (selectedAmounts[pc.id]) {
                price += selectedAmounts[pc.id] * pc.price
            }
        })
        return price
    }, [priceCodes, selectedAmounts])

    useEffect(() => {
        let currentRank = ranks.find((rank) => rank.id === selectedRank)

        const seats =
            currentRank?.availableSeats && currentRank?.availableSeats < 10
                ? currentRank?.availableSeats
                : 10

        setMaxSelectableSeats(seats || 10)
    }, [ranks, selectedAmounts, selectedRank])

    useEffect(() => {
        if (eventStatus !== 'idle' && eventStatus !== 'loading' && event === null) {
            navigate('/')
        }
    }, [eventStatus, event, navigate])

    const floorplanUrl = useMemo(() => {
        if (
            event &&
            floorplanBaseUrl !== undefined &&
            // Only show floorplanner if there are ranks excluding 'volloop'
            ranksData?.success &&
            ranksData.data.filter((r) => r.id !== 0).length
        ) {
            return getFloorplanImageUrl({
                baseUrl: floorplanBaseUrl,
                locationId: event.location_id,
                category: event.category
            })
        }
        return null
    }, [event, floorplanBaseUrl, ranksData])

    const locationSvg =
        locationStatus === 'success' &&
        settings.getShowLayout() &&
        groupedEvent &&
        locationsData?.locations.find((l) => l.zaal_id === groupedEvent[0].location_id)

    const AddToCart = () => {
        if (!event) {
            throw new Error()
        }

        return (
            <AddToCartWrapper>
                {settings.getCartEnabled() && (
                    <FinalizeButton
                        disabled={
                            selectedRank === null || !hasSelectedAmount() || !isCinevilleValid
                        }
                        onClick={() => {
                            if (selectedRank !== null && hasSelectedAmount()) {
                                cart.addToCart({
                                    event,
                                    rank: selectedRank,
                                    selectedPriceCodes: selectedAmounts,
                                    location: selectedLocation,
                                    priceCodes
                                })

                                navigate('/')
                            }
                        }}
                    >
                        Voeg toe aan winkelmandje
                    </FinalizeButton>
                )}
            </AddToCartWrapper>
        )
    }

    const { Overlays, setAccountActive, startPayment } = useOrder({
        events:
            event && selectedRank !== null && hasSelectedAmount()
                ? [
                      {
                          eventId: event.id,
                          location: selectedLocation,
                          priceCodes: selectedAmounts,
                          rank: selectedRank,
                          from: event.from
                      }
                  ]
                : [],
        totalPrice
    })

    const isCineville = (p: IPriceCode) => {
        return p.name.toLowerCase() === 'cinevill' || p.name.toLowerCase() === 'cineville'
    }

    const [cinevilleValidMap, setCinevilleValidMap] = useState<{
        [pricecode: string]: { [card: string]: boolean | undefined }
    }>({})

    let isCinevilleValid = true

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_k, v] of Object.entries(cinevilleValidMap)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_k2, v2] of Object.entries(v)) {
            if (v2 === false) {
                isCinevilleValid = false
            }
        }
    }

    return (
        <>
            <ThemeContext event={event}>
                {eventStatus === 'loading' ||
                ranksStatus === 'loading' ||
                imageStatus === 'loading' ? (
                    <LoadingWrapper>
                        <Spinner />
                    </LoadingWrapper>
                ) : event === null ? (
                    <div>Event niet gevonden!!!!</div>
                ) : (
                    <Wrapper>
                        <Event>
                            {locationSvg && (
                                <SvgDiv style={{ height: '398px', position: 'relative' }}>
                                    {HTMLReactParser(locationSvg.svg, {
                                        replace: (domNode: any) => {
                                            if ('name' in domNode && domNode.name === 'style') {
                                                return <></>
                                            }

                                            if (
                                                'name' in domNode &&
                                                (domNode.name === 'polygon' ||
                                                    domNode.name === 'rect') &&
                                                domNode.attribs['data-name']
                                            ) {
                                                delete domNode.attribs.class
                                                const name = domNode.attribs['data-name']

                                                const Type: keyof JSX.IntrinsicElements = `${
                                                    domNode.name === 'polygon' ? 'polygon' : 'rect'
                                                }`

                                                return (
                                                    <Type
                                                        {...attributesToProps(domNode.attribs)}
                                                        {...(name === selectedLocation && {
                                                            'data-selected': ''
                                                        })}
                                                        onClick={() => {
                                                            if (
                                                                ranks.find(
                                                                    (r) =>
                                                                        (r.locations.find(
                                                                            (l) =>
                                                                                l.name ===
                                                                                name.substr(2)
                                                                        )?.seats_free || 0) > 0
                                                                )
                                                            ) {
                                                                setSelectedLocation(
                                                                    name === selectedLocation
                                                                        ? undefined
                                                                        : domNode.attribs[
                                                                              'data-name'
                                                                          ]
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        {domToReact(domNode.children)}
                                                    </Type>
                                                )
                                            }
                                        }
                                    })}
                                </SvgDiv>
                            )}

                            {!locationSvg && (
                                <div style={{ height: '398px', position: 'relative' }}>
                                    {floorplanBaseUrl && floorplanUrl ? (
                                        <EventImage
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0
                                            }}
                                            key={'event_floorplan'}
                                        >
                                            {<img alt="" src={floorplanUrl} />}
                                        </EventImage>
                                    ) : image ? (
                                        <EventImage>
                                            {<img alt="" src={image} style={defaultImgStyle} />}
                                        </EventImage>
                                    ) : null}
                                </div>
                            )}

                            <EventInfo>
                                <EventTitle>{event.title}</EventTitle>
                                <EventSubTitle>{event.subtitle}</EventSubTitle>
                                <LocationName>{event.location_name}</LocationName>
                                {event.until && (
                                    <EventDuration>
                                        Speelduur: {moment(event.until).diff(event.from, 'minutes')}{' '}
                                        minuten
                                    </EventDuration>
                                )}

                                {settings.getShowDateOnCard() && (
                                    <EventDetailDate>
                                        {moment(event.from).calendar(null, {
                                            sameDay: '[Vandaag]',
                                            nextDay: '[Morgen]',
                                            nextWeek: 'dddd',
                                            lastDay: '[Gisteren]',
                                            lastWeek: '[Afgelopen] dddd',
                                            sameElse: 'DD MMM YYYY'
                                        })}
                                    </EventDetailDate>
                                )}

                                <EventDates>
                                    {groupedEvent?.map((s) => {
                                        return (
                                            <EventDate
                                                soldOut={s.salestatus === 'uitverkocht'}
                                                onClick={() => {
                                                    s.salestatus !== 'uitverkocht' &&
                                                        navigate('/bestel/' + s.id)
                                                }}
                                                key={'bestel_' + s.id}
                                                selected={s.id === eventId}
                                            >
                                                {moment(s.from).format('H:mm')}
                                            </EventDate>
                                        )
                                    })}
                                </EventDates>

                                {hasAvailableSeats === false && (
                                    <Message>
                                        {appContext.appSettings?.customText?.soldOut ||
                                            'Er zijn helaas geen plaatsen meer beschikbaar voor deze voorstelling.'}
                                    </Message>
                                )}

                                {filteredRanks.length > 1 && (
                                    <RankSelection>
                                        <RankText>Selecteer een rang:</RankText>
                                        <Ranks>
                                            {filteredRanks.map((r) => {
                                                return (
                                                    <Rank
                                                        active={r.id === selectedRank}
                                                        onClick={() => {
                                                            if (r.availableSeats > 1) {
                                                                selectRank(r.id)
                                                                setHasAvailableSeats(true)
                                                            }
                                                        }}
                                                        key={r.id}
                                                        disabled={r.availableSeats < 1}
                                                    >
                                                        {r.name} - ({r.availableSeats} vrije
                                                        plaatsen)
                                                    </Rank>
                                                )
                                            })}
                                        </Ranks>
                                    </RankSelection>
                                )}

                                {priceCodes.length > 0 && (
                                    <PriceCodeSelection>
                                        <PriceCodeText>
                                            Selecteer een aantal voor de gewenste prijscode:
                                        </PriceCodeText>

                                        <PriceCodes>
                                            {priceCodes.map((p, index) => (
                                                <React.Fragment key={`pricecode_${p.id}_${index}`}>
                                                    <PriceCodeCell key={`name_${p.id}_${index}`}>
                                                        <PriceCodeName>{p.name}</PriceCodeName>
                                                    </PriceCodeCell>
                                                    <PriceCodeCell
                                                        middle
                                                        key={`price_${p.id}_${index}`}
                                                    >
                                                        <Price>
                                                            {priceFormatter.format(p.price)}
                                                        </Price>
                                                    </PriceCodeCell>
                                                    <PriceCodeCell
                                                        middle
                                                        key={`min_${p.id}_${index}`}
                                                    >
                                                        <AmountButton
                                                            onClick={() => {
                                                                setSelectedAmounts({
                                                                    ...selectedAmounts,
                                                                    [p.id]: Math.max(
                                                                        (selectedAmounts[p.id] ??
                                                                            0) - 1,
                                                                        0
                                                                    )
                                                                })
                                                            }}
                                                        >
                                                            -
                                                        </AmountButton>
                                                    </PriceCodeCell>
                                                    <PriceCodeCell
                                                        middle
                                                        key={`amount_${p.id}_${index}`}
                                                    >
                                                        <SelectedAmount>
                                                            {selectedAmounts[p.id] ?? 0}
                                                        </SelectedAmount>
                                                    </PriceCodeCell>
                                                    <PriceCodeCell
                                                        middle
                                                        key={`plus_${p.id}_${index}`}
                                                    >
                                                        <AmountButton
                                                            onClick={() => {
                                                                setSelectedAmounts({
                                                                    ...selectedAmounts,
                                                                    [p.id]: Math.min(
                                                                        (selectedAmounts[p.id] ??
                                                                            0) + 1,
                                                                        maxSelectableSeats
                                                                    )
                                                                })
                                                            }}
                                                        >
                                                            +
                                                        </AmountButton>
                                                    </PriceCodeCell>

                                                    {/* Pricecode has a limit of either 8 or 12 chars */}
                                                    {selectedAmounts[p.id] > 0 &&
                                                        isCineville(p) && (
                                                            <div>
                                                                <p>Mijn Cineville pasnummers</p>
                                                                {range(selectedAmounts[p.id]).map(
                                                                    (r) => (
                                                                        <CinevilleDetail
                                                                            key={r}
                                                                            isValid={
                                                                                cinevilleValidMap[
                                                                                    p.id
                                                                                ]?.[r] || false
                                                                            }
                                                                            setIsValid={(isValid) =>
                                                                                setCinevilleValidMap(
                                                                                    (m) => ({
                                                                                        ...m,
                                                                                        [p.id]: {
                                                                                            ...m[
                                                                                                p.id
                                                                                            ],
                                                                                            [r]: isValid
                                                                                        }
                                                                                    })
                                                                                )
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                </React.Fragment>
                                            ))}
                                        </PriceCodes>
                                    </PriceCodeSelection>
                                )}

                                {appContext.appSettings?.customText?.infoMovieDetail &&
                                    event.category === 'film' && (
                                        <EventInfoTekst>
                                            {appContext.appSettings?.customText?.infoMovieDetail}
                                        </EventInfoTekst>
                                    )}

                                {appContext.appSettings?.customText?.infoTheaterDetail &&
                                    event.category === 'theater' && (
                                        <EventInfoTekst>
                                            {appContext.appSettings?.customText?.infoTheaterDetail}
                                        </EventInfoTekst>
                                    )}
                            </EventInfo>
                        </Event>

                        {Overlays}

                        {hasAvailableSeats === true && (
                            <FinalizeInfo>
                                <TotalPrice>Totaal: {priceFormatter.format(totalPrice)}</TotalPrice>

                                <FinalizeButton
                                    disabled={
                                        selectedRank === null ||
                                        !hasSelectedAmount() ||
                                        !isCinevilleValid
                                    }
                                    onClick={() => {
                                        if (
                                            selectedRank === null ||
                                            !hasSelectedAmount() ||
                                            !isCinevilleValid
                                        ) {
                                            return
                                        }

                                        if (settings.getAnonymousBooking() === 'account') {
                                            if (selectedRank !== null && hasSelectedAmount()) {
                                                setAccountActive(true)
                                            }
                                        } else if (
                                            settings.getAnonymousBooking() === 'sameDayAnonymous' &&
                                            moment(event.from).day() !== moment().day()
                                        ) {
                                            if (selectedRank !== null && hasSelectedAmount()) {
                                                setAccountActive(true)
                                            }
                                        } else if (
                                            settings.getCartEnabled() &&
                                            cart.cart.events.length > 0
                                        ) {
                                            if (selectedRank !== null && hasSelectedAmount()) {
                                                cart.addToCart({
                                                    event,
                                                    rank: selectedRank,
                                                    selectedPriceCodes: selectedAmounts,
                                                    location: selectedLocation,
                                                    priceCodes
                                                })

                                                navigate('/winkelmandje')
                                            }
                                        } else {
                                            startPayment()
                                        }
                                    }}
                                >
                                    {appContext.appSettings?.customText?.orderButton || 'Afrekenen'}
                                </FinalizeButton>

                                <AddToCart />
                            </FinalizeInfo>
                        )}
                    </Wrapper>
                )}
            </ThemeContext>
        </>
    )
}

const CinevilleDetail = ({
    isValid,
    setIsValid
}: {
    isValid: boolean
    setIsValid: (isValid: boolean | undefined) => void
}) => {
    useEffect(() => {
        setIsValid(false)
        return () => setIsValid(undefined)
    }, []) // eslint-disable-line

    const validateCinevilleTicket = debounce(async (code: string) => {
        return await axios
            .get(
                `${settingsBaseUrl}/framework/public/ajax/site/externalTicketValidation.php?action=validateCinevilleTicket&barcode=${code}`,
                {
                    withCredentials: true
                }
            )
            .then((response) => {
                setIsValid(response.data?.['status'] === 'OK')
            })
    }, 500)

    return (
        <StyledCinevilleDetail>
            {isValid && <div>✅</div>}

            <input onChange={(e) => validateCinevilleTicket(e.target.value)} />
        </StyledCinevilleDetail>
    )
}

const StyledCinevilleDetail = styled.div`
    display: block;
    margin: 4px;

    div {
        display: inline-block;
    }

    input {
        padding: 8px;
    }
`
